
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆充电房',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP55',
            //                     sort: 3
            //                 }, {
            //                     descName: this.$store.state.pageset.pcshow ? '2900mm×2000mm×2500mm' : '2.9m×2m×2.5m',
            //                     sort: 4
            //                 }, {
            //                     descName: '最大8级风',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '外形尺寸 (长x宽x高）',
            //                     sort: 8
            //                 }, {
            //                     descName: '抗风能力',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [{
            //                 descName: '具备防风防汛、通风防潮、恒温、防爆、防火功能，能满足机器人全天候存储和自动充电的要求。能够有效地提高充电桩和机器人的使用寿命，提高机器人充电的稳定性，扩大适用范围，减少极端天气对巡检机器人充电的影响。',
            //                 sort: 1
            //             },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '安装环境',
            //                     sort: 1
            //                 }, {
            //                     descName: '房体安装场地整体要求平整，非低洼处，不易积水，有强风区域应考虑做与地面的加固连接处理，环境中无破坏性绝缘的腐蚀性物质，安装的场所无显著冲击和振动。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电动门控制',
            //                     sort: 1
            //                 }, {
            //                     descName: '充电房门采用电动卷帘门，在机器人启动进行巡检任务或者执行巡检任务归来时，会自动打开电动卷帘门。当机器人保持充电状态时，卷帘门自动关闭。卷帘门也可人工进行本地控制',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '远程控制',
            //                     sort: 1
            //                 }, {
            //                     descName: '可以在巡检平台远程查看、控制充电房的设备，了解充电房的运行情况',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '加热/制冷系统(选配)',
            //                     sort: 1
            //                 }, {
            //                     descName: '充电房内安装加热/制冷设施，可手动或远程开关加热/制冷设备',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '微气象站(选配)',
            //                     sort: 1
            //                 }, {
            //                     descName: '可定制机器人配套的防爆微气象站。微气象站提供温湿度、气压、风速、风向和降雨最六种常见的气象环境采集能力。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         }
            //     ],
                elmentList:[]
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.$nextTick(() => {
                this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            });
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}